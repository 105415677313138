<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <c-search-box @enter="getSystems">
          <template slot="search">
            <div class="col-3">
              <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
            </div>
            <div class="col-3">
              <c-select
                type="search"
                codeGroupCd="SAI_INTERNAL_AUDIT_CLASS_CD"
                itemText="codeName"
                itemValue="code"
                label="ISO 구분"
                name="saiInternalAuditClassCd"
                v-model="searchParam.saiInternalAuditClassCd"
              ></c-select>
            </div>
            <div class="col-4">
              <c-dept
                type="search"
                name="targetDeptCd"
                label="피심사부서"
                :isFirstValue="false"
                v-model="searchParam.targetDeptCd"
              ></c-dept>
            </div>
            <div class="col-2">
              <c-select
                :comboItems="useFlagItems"
                type="search"
                itemText="codeName"
                itemValue="code"
                name="useFlag"
                label="사용여부"
                v-model="searchParam.useFlag"
              ></c-select>
            </div>
          </template>
        </c-search-box>
        <c-table
          ref="systemTable"
          title="시스템요소 목록"
          tableId="systemTable"
          :columns="systemGrid.columns"
          :data="systemGrid.data"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :isExcelDown="false"
          :filtering="true"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn
                v-if="editable"
                label="엑셀업로드"
                icon="upload"
                @btnClicked="excelUploadData" />
              <c-btn v-if="editable" label="추가" icon="add" @btnClicked="addRowSystem" />
              <c-btn 
                v-if="editable" 
                :isSubmit="isSaveSystem"
                :url="saveSystemUrl"
                :param="systemGrid.data"
                mappingType="PUT"
                label="저장" 
                icon="save"
                @beforeAction="saveSystem"
                @btnCallback="saveSystemCallback" />
              <c-btn label="검색" icon="search" @btnClicked="getSystems" />
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props }">
            <template>
              <!-- <q-chip
                v-if="props.row.editFlag !== 'C'"
                outline square
                color="blue"
                :clickable="true"
                text-color="white"
                style="margin-bottom:4px !important;"
                size="9px"
                @click.stop="getChecklist(props.row.saiInternalAuditChecklistSystemId)">
                ▶
              </q-chip> -->
              <q-btn outline color="blue" size="9px" @click.stop="getChecklist(props.row.saiInternalAuditChecklistSystemId)">
                ▶
              </q-btn>
            </template>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <c-table
          ref="checkGrid"
          title="체크리스트 항목 목록"
          tableId="checkGrid"
          :columnSetting="false"
          :isFullScreen="false"
          :isExcelDown="false"
          :columns="checkGrid.columns"
          :data="checkGrid.data"
        >
          <!-- selection="multiple"
          rowKey="saiInternalAuditChecklistId" -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable && selectSystemId" label="추가" icon="add" @btnClicked="addCheck" />
              <!-- <c-btn v-if="editable && selectSystemId" label="삭제" icon="remove" @btnClicked="deleteCheck" /> -->
              <c-btn v-if="editable && selectSystemId" label="저장" icon="save" @btnClicked="saveCheck" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'internalChecklist',
  data() {
    return {
      popupOptions: {
        isFull: false,
        width: '40%',
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
      searchParam: {
        plantCd: null,
        saiInternalAuditClassCd: null,
        useFlag: 'Y',
        targetDeptCd: '',
      },
      systemGrid: {
        columns: [
          {
            name: 'plantCd',
            field: 'plantCd',
            label: '사업장',
            align: 'center',
            sortable: true,
            required: true,
            style: 'width:80px',
            type: 'plant',
          },
          {
            required: true,
            name: 'saiInternalAuditClassCd',
            field: 'saiInternalAuditClassCd',
            label: 'ISO 구분',
            align: 'center',
            style: 'width:80px',
            type: 'select',
            codeGroupCd: 'SAI_INTERNAL_AUDIT_CLASS_CD',
            sortable: false,
          },
          {
            name: 'targetDeptCd',
            field: 'targetDeptCd',
            label: '피심사부서',
            align: 'left',
            style: 'width:160px',
            sortable: false,
            type: 'deptMulti',
            deptCd: 'targetDeptCd',
            isFirstValue: false,
          },
          {
            required: true,
            name: 'systemElementsName',
            field: 'systemElementsName',
            label: '시스템요소',
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            style: 'width:40px',
            type: 'check',
            true: 'Y',
            false: 'N',
            sortable: false,
          },
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: '순번',
            align: 'center',
            style: 'width:50px',
            sortable: true,
            type: 'number',
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: '항목',
            align: 'center',
            style: 'width:40px',
            type: 'custom',
            sortable: false
          },
        ],
        data: [],
      },
      checkGrid: {
        columns: [
          {
            name: 'itemName',
            field: 'itemName',
            label: '내부심사 항목',
            align: 'left',
            sortable: true,
            type: 'textarea'
          },
          {
            name: 'aimMatter',
            field: 'aimMatter',
            label: '내부심사 시 착안사항',
            align: 'left',
            sortable: true,
            style: 'width:200px',
            type: 'textarea'
          },
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: '순번',
            type: 'number',
            align: 'center',
            style: 'width:60px',
            sortable: true,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            type: 'check',
            sortable: true,
            align: 'center',
            style: 'width:60px',
            true: 'Y',
            false: 'N'
          },
        ],
        data: [],
      },
      selectSystemId: '',
      checklist: {
        saiInternalAuditChecklistId: '',  // 내부감사 체크리스트 항목 일련번호
        saiInternalAuditChecklistSystemId: '',  // 내부감사 체크리스트 시스템 요소 일련번호
        itemName: '',  // 내부심사 항목
        aimMatter: '',  // 내부심사 시 착안 사항
        useFlag: 'Y',  // 사용여부
        sortOrder: '',  // 순번
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
      },
      useFlagItems: [],
      editable: true,
      mappingType: 'POST',
      saveMode: false,
      isSaveSystem: false,
      isSaveCheck: false,
      listSystemUrl: '',
      saveSystemUrl: '',
      excelSystemUrl: '',
      listCheckUrl: '',
      detailCheckUrl: '',
      saveCheckUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    showDetail() {
      return this.selectSystemId // 부모가 선택되어진 경우
    },
    detailDisabled() {
      return !this.showDetail || !this.saveMode
    }
  },
  methods: {
    init() {
      this.useFlagItems = [
        { code: 'Y', codeName: this.$language('사용') },
        { code: 'N', codeName: this.$language('미사용') },
      ]
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listSystemUrl = selectConfig.sai.internal.base.systemChecklist.list.url;
      this.saveSystemUrl = transactionConfig.sai.internal.base.systemChecklist.save.url;
      this.excelSystemUrl = transactionConfig.sai.internal.base.systemChecklist.excel.url;
      this.listCheckUrl = selectConfig.sai.internal.base.checklist.list.url;
      this.detailCheckUrl = selectConfig.sai.internal.base.checklist.get.url;
      this.saveCheckUrl = transactionConfig.sai.internal.base.checklist.update.url;
      // code setting
      // list setting
      this.getSystems();
    },
    getSystems() {
      this.checkGrid.data = [];
      this.$http.url = this.listSystemUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam
      this.$http.request((_result) => {
        this.systemGrid.data = _result.data;
      },);
    },
    getChecklist(_id) {
      this.$set(this.$data, 'selectSystemId', _id)
      this.saveMode = false;
      this.$http.url = this.listCheckUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        saiInternalAuditChecklistSystemId: _id
      };
      this.$http.request((_result) => {
        this.checkGrid.data = _result.data;
        this.reset();
      },);
    },
    reset() {
      this.saveMode = false;
      Object.assign(this.$data.checklist, this.$options.data().checklist);
    },
    addRowSystem() {
      this.reset();
      this.systemGrid.data.splice(0, 0, {
        saiInternalAuditChecklistSystemId: uid(),  // 내부감사 체크리스트 시스템 요소 일련번호
        plantCd: null,  // 사업장코드
        saiInternalAuditClassCd: null,  // ISO 구분
        systemElementsName: '',  // 시스템 요소
        targetDeptCd: '',
        useFlag: 'Y',  // 사용여부
        sortOrder: 0,  // 순번
        editFlag: 'C',
        regUserId: this.$store.getters.user.userId,
      })
    },
    saveSystem() {
      if (this.$comm.validTable(this.systemGrid.columns, this.systemGrid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인', 
          message: '저장하시겠습니까?',
          type: 'info',
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSaveSystem = !this.isSaveSystem
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveSystemCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getSystems();
      this.reset();
    },
    addCheck() {
      this.checkGrid.data.push({
        saiInternalAuditChecklistId: uid(),  // 내부감사 체크리스트 항목 일련번호
        saiInternalAuditChecklistSystemId: this.selectSystemId,  // 내부감사 체크리스트 시스템 요소 일련번호
        itemName: '',  // 내부심사 항목
        aimMatter: '',  // 내부심사 시 착안 사항
        useFlag: 'Y',  // 사용여부
        sortOrder: '',  // 순번
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        editFlag: 'C'
      })
    },
    saveCheck() {
      if (this.$comm.validTable(this.checkGrid.columns, this.checkGrid.data)) {
        let saveData = this.checkGrid.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        this.$_.forEach(saveData, item => {
          item.regUserId = this.$store.getters.user.userId;
          item.chgUserId = this.$store.getters.user.userId;
        });
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: '확인', 
            message: '저장하시겠습니까?',  
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.saveCheckUrl;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                this.getChecklist(this.selectSystemId)
                window.getApp.$emit('APP_REQUEST_SUCCESS');
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내', 
            message: '변경된 데이터가 없습니다.',
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    deleteCheck() {
      let selectData = this.$refs['checkGrid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인', 
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.saveCheckUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              
              this.$_.forEach(selectData, item => {
                this.checkGrid.data = this.$_.reject(this.checkGrid.data, item);
              })
              this.$refs['checkGrid'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    excelUploadData() {
      this.popupOptions.title = '내부심사 체크리스트 업로드';
      this.popupOptions.target = () => import(`${'./internalChecklistExcelUpload.vue'}`);
      this.popupOptions.isFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeExcelUploadPopup;
    },
    closeExcelUploadPopup(_result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (_result && _result.length > 0) {
        let s_data = this.$_.filter(_result, (item) => { return !item.error_message })
        let mst_data = [];
        let mst_data_targetName = [];
        this.$_.forEach(s_data, item => {
          item.useFlag = 'Y';
          item.regUserId = this.$store.getters.user.userId;  // 등록자 ID
          if (this.$_.indexOf(mst_data_targetName, item.plantCd + item.saiInternalAuditClassCd + item.systemElementsName) < 0) {
            mst_data_targetName.push(item.plantCd + item.saiInternalAuditClassCd + item.systemElementsName)
            mst_data.push({
              saiInternalAuditChecklistSystemId: uid(),  // 내부감사 체크리스트 시스템 요소 일련번호
              plantCd: item.plantCd,  // 사업장코드
              saiInternalAuditClassCd: item.saiInternalAuditClassCd,  // ISO 구분
              systemElementsName: item.systemElementsName,  // 시스템 요소
              targetDeptCd: item.targetDeptCd,
              useFlag: 'Y',  // 사용여부
              sortOrder: item.msortOrder,  // 순번
              editFlag: 'C',
              regUserId: this.$store.getters.user.userId,
              items: [],
            })
          }
        })
        this.$_.forEach(mst_data, _mst => {
          this.$_.forEach(s_data, item => {
            if ((_mst.plantCd + _mst.saiInternalAuditClassCd + _mst.systemElementsName) == (item.plantCd + item.saiInternalAuditClassCd + item.systemElementsName)) {
              item.useFlag = 'Y';
              item.regUserId = this.$store.getters.user.userId;  // 등록자 ID
              _mst.items.push({
                saiInternalAuditChecklistId: uid(),  // 내부감사 체크리스트 항목 일련번호
                saiInternalAuditChecklistSystemId: uid(),  // 내부감사 체크리스트 시스템 요소 일련번호
                itemName: item.itemName,  // 내부심사 항목
                aimMatter: item.aimMatter,  // 내부심사 시 착안 사항
                useFlag: 'Y',  // 사용여부
                sortOrder: item.sortOrder,  // 순번
                regUserId: this.$store.getters.user.userId,
                chgUserId: this.$store.getters.user.userId,
              })
            }
          })
        })

        this.$http.url = this.excelSystemUrl;
        this.$http.type = 'POST';
        this.$http.param = mst_data;
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.getSystems();
        });
      }
    }
  }
};
</script>
